html {
  height: 100%;
}

:root {
  --font-family: '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

html[data-theme="ishealth-mobile-msk"] {
  /* font style */
  --font-family: 'MSK-WorkSans', sans-serif ; 
  --font-family-name: 'MSK-WorkSans';
  --font-thin: url(../public/fonts/msk-fonts/WorkSans-Thin.ttf);
  --font-extra-light: url(../public/fonts/msk-fonts/WorkSans-ExtraLight.ttf);
  --font-light: url(../public/fonts/msk-fonts/WorkSans-Light.ttf);
  --font-regular: url(../public/fonts/msk-fonts/WorkSans-Regular.ttf);
  --font-medium: url(../public/fonts/msk-fonts/WorkSans-Medium.ttf);
  --font-semi-bold: url(../public/fonts/msk-fonts/WorkSans-SemiBold.ttf);
  --font-bold: url(../public/fonts/msk-fonts/WorkSans-Bold.ttf);
  --font-extra-bold: url(../public/fonts/msk-fonts/WorkSans-ExtraBold.ttf);
  --font-black: url(../public/fonts/msk-fonts/WorkSans-Black.ttf);
}

/* Thin	100
Extra Light / Ultra Light	200
Light	300
Regular / Normal	400
Medium	500
Semi Bold / Demi Bold	600
Bold	700
Extra Bold / Ultra Bold	800
Black / Heavy	900 */

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-thin) format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-extra-light) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-light) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-regular) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-medium) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-semi-bold) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-bold) format('truetype');
  font-weight: Bold;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-extra-bold) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: var(--font-family-name);
  src: var(--font-black) format('truetype');
  font-weight: 900;
}

body {
  margin: 0;
  height: 100%;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-family) !important;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
