/* DateTextBox.css react-lib */
.daterangepicker .ui.dropdown.monthselect, .daterangepicker .ui.dropdown.yearselect {
    font-size: 12px;
    padding: 5px;
    margin: 0 5px 0 0;
    height: auto;
    cursor: default;
    width: auto;
}

.YearMonthForm .ui.tiny.form {
    display: flex;
    z-index: 50;
}

.YearMonthForm .ui.dropdown .menu > .item {
    font-size: 0.85rem;
    padding: 0.5rem 0.7rem !important;
}

.YearMonthForm .ui.form select {
    padding: 0px;
}

.YearMonthForm .ui.form select.month {
    width: 50%;
    margin-right: 2%;
}

.YearMonthForm .ui.form select.year {
    width: 40%;
    margin-left: 2%;
    margin-right: 2%;
}

.YearMonthForm {
    display: table-caption;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
    margin-left: 1.4em;
    text-align: left;
}

.DayPickerInput-Overlay {
    z-index: 50;
}

.ui.form .inline.fields .field:not(.wide) .DayPickerInput,
.ui.form .inline.field:not(.wide) .DayPickerInput {
    width: auto;
}

.ui.form .field .DayPickerInput,
.ui.form .fields .field .DayPickerInput,
.ui.form .wide.field .DayPickerInput {
    width: 100%;
}

.DayPicker-NavButton--prev {
    left: 0.7rem;
}

.DayPicker-NavButton--next {
    right: 0.7rem;
}

.DayPickerInput.fluid,
.ui .input .fluid {
    width: 100%
}

.MuiInput-no-underline .MuiInput-underline:before{
    content: "" !important;
    border-bottom: 0 !important;
}

.MuiInput-no-underline .MuiInput-underline:after{
    content: "" !important;
    border-bottom: 0 !important;
}

.DayPicker-Day {
    width: 2.4em;
    aspect-ratio: 1 / 1;
}

.DayPicker-wrapper {
    width: max-content;
}

.DayPickerInput-Overlay--right {
    left: auto;
    right: 0;
}